import isIPv4Host from './isIPv4Host';

export const getSubDomainKey = () => {
  const isDomainHost = !isIPv4Host(window.location.host);
  let subDomainKey = null;

  if (isDomainHost) {
    const techbarName = 'techbar';

    // Special case for Techbar
    if (window.location.host.indexOf(techbarName) >= 0) subDomainKey = techbarName;
    else {
      const hostParts = window.location.host.split('.'); // todo: nach slug filtern

      if (hostParts.length === 3) [subDomainKey] = hostParts;
    }
  }

  if (!subDomainKey) subDomainKey = 'www';

  return subDomainKey;
};

export default getSubDomainKey;
