/* eslint-disable import/prefer-default-export */
export const menu = [
  {
    name: "Leistungen",
    url: "leistungen",
    children: [
      {
        name: "Automation",
        url: "automation",
        children: [
          {
            name: "Robotic Process Automation",
            url: "rpa",
          },
          {
            name: "Prozessdigitalisierung",
            url: "prozessdigitalisierung",
          },
          {
            name: "Testautomatisierung",
            url: "testautomatisierung",
          },
        ],
      },
      {
        name: "Cloud Computing",
        url: "cloud-computing",
      },
      {
        name: "Data Analytics",
        url: "data-analytics",
      },
      {
        name: "Artificial Intelligence",
        url: "ai",
      },
      {
        name: "Validation Qualification",
        url: "validation-qualification",
      },
      {
        name: "Digitale Verwaltung",
        url: "digitale-verwaltung",
        children: [
          {
            name: "Smart Government",
            url: "smart-government",
          },
          {
            name: "IT Beschaffungsmanagement",
            url: "it-beschaffungsmanagement",
          },
        ],
      },
      {
        name: "Business Applications",
        url: "business-applications",
        children: [
          {
            name: "SAP",
            url: "sap",
          },
        ],
      },
      {
        name: "IT Consulting",
        url: "it-consulting",
      },
      {
        name: "Business Consulting",
        url: "business-consulting",
        children: [
          {
            name: "Projektmanagement",
            url: "projektmanagement",
          },
        ],
      },
    ],
  },
  {
    name: "Branchen",
    url: "branchen",
    children: [
      {
        name: "Life Science",
        url: "life-science",
      },
      {
        name: "Finanzwesen",
        url: "finanzwesen",
      },
      {
        name: "Meldewesen",
        url: "meldewesen",
      },
      {
        name: "SIEM",
        url: "siem",
      },
      {
        name: "IT GRC",
        url: "it-grc",
      },
      {
        name: "Public Sector",
        url: "public-sector",
      },
      {
        name: "Industrie",
        url: "industrie",
      },
      {
        name: "Automotive",
        url: "automotive",
      },
    ],
  },
  {
    name: "Karriere",
    url: "karriere",
  },
  {
    name: "Unternehmen",
    url: "unternehmen",
  },
  {
    name: "Aktuelles",
    url: "aktuelles",
  },
];
