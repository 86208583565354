import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { scrollToHash } from '../../global-effects';

// Components
import CookieConsentBox from '../CookieConsentBox';
import PageViewTracker from '../PageViewTracker';
import HeaderHelmet from '../HeaderHelmet';
import Loader from '../Loader';
import NewsItemPageContent from '../NewsItemPageContent';
import PageFooter from '../PageFooter/PageFooter';
import PageHeader from '../PageHeader/PageHeader';
import { getSubDomainKey, person2Team } from '../../utils';

// Services
import ApiService from '../../services/ApiService';

// Store
import { fetchMainMenu, selectMainMenu } from '../../state/store/slices/mainMenuSlice';
import { fetchSecondaryMenu } from '../../state/store/slices/secondaryMenuSlice';
import { useAppSelector } from '../../state/hooks';

import './NewsItemPage.scss';
import store from '../../state/store';
import getDepartmentKey from '../../utils/getDepartmentKey';
import getPrefixKey from '../../utils/getPrefixKey';

const buildData = (newsItem) => {
  const header = {
    title: newsItem.title,
    subTitle: newsItem.subTitle,
    image: newsItem.image,
    imageOverlay: newsItem.imageOverlay,
    theme: 'Syncwork',
  };

  return {
    header,
    ...newsItem,
  };
};

const NewsItemPage = () => {
  // switch to different navigation when on topicArea
  const subDomainKey = getSubDomainKey();
  const departmentKey = getDepartmentKey();
  const prefixKey = getPrefixKey();
  console.log(prefixKey);

  useEffect(() => {
    store.dispatch(fetchMainMenu(subDomainKey));
    store.dispatch(fetchSecondaryMenu(departmentKey));
  }, [departmentKey, prefixKey, subDomainKey]);

  const { slug }: any = useParams();
  console.log(slug);
  // TopicArea
  const mainMenu = useAppSelector(selectMainMenu);
  /*
  const secondaryMenu = useAppSelector(selectSecondaryMenu);
  useEffect(() => {
    if (!mainMenu) { mainMenu = secondaryMenu; }
  }, [mainMenu, secondaryMenu]);
  */

  const scrolledRef = useRef(false);
  const { hash } = useLocation();

  const [data, setData] = useState(null);
  const setDataAndPossiblyScroll = (data2) => {
    setData(data2);
    scrollToHash(scrolledRef, hash);
  };

  useEffect(() => {
    ApiService.get(`/news-items?slug=${slug}`).then(response => {
      if (response.data.length) {
        setDataAndPossiblyScroll(buildData(response.data[0]));
      }
    });

    if (subDomainKey) {
      store.dispatch(fetchMainMenu(subDomainKey)); // different navigation for techbar and karriere
    }
    store.dispatch(fetchSecondaryMenu(departmentKey || "")); // to set or reset secondary Navigation
  }, [slug]);

  if (!data) {
    return (<></>);
  }

  // show loader as long there is no site menu
  if (!mainMenu) {
    return (
      <div className="sw-page">
        <Loader />
      </div>
    );
  }

  // convert any instances of team === {} to null, this occurs when a team is deleted
  if (isEmpty(data.team)) {
    data.team = null;
  }

  if (data.contactPerson) {
    data.team = person2Team(data.contactPerson);
  }

  return (
    <>
      <CookieConsentBox googleTagManagerId={mainMenu.googleTagManagerId} />
      <PageViewTracker />
      <HeaderHelmet header={data.header} mainMenu={mainMenu} meta={data.meta} />
      <div className="sw-page">
        <PageHeader data={data} />
        <NewsItemPageContent data={data} />
        <PageFooter team={data.team} />
      </div>
    </>
  );
};

export default NewsItemPage;
