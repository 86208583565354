// import getPrefixKey from "./getPrefixKey";

const formatUrl = (url) => {
  const safeUrl = url ?? '';
  const isK8sProductionEnvironment = process.env.REACT_APP_K8S_ENV === 'production';

  /*
  const isInternalLink = !safeUrl.includes(":"); // http:// or https://
  const prefixKey = getPrefixKey();

  if (prefixKey && isInternalLink) {
    const dePrefixedUrl = safeUrl
      .replace(`/${prefixKey}-`, "")
      .replace(`/${prefixKey}/`, "")
      .replace(`${prefixKey}-`, "")
      .replace(`${prefixKey}/`, "");

    if (dePrefixedUrl.startsWith("/")) {
      return `/${prefixKey}${dePrefixedUrl}`;
    }
    return `/${prefixKey}/${dePrefixedUrl}`;
  }
    */

  if (!isK8sProductionEnvironment) {
    return safeUrl.replace(/(syncwork\.de)($|[^v])/, '$1v$2')
      .replace(/[/]{2}(techbar.berlin)/, '//beta.$1');
  }

  return safeUrl;
};

export default formatUrl;
