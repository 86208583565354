import isLifeScience from "./isLifeScience";
import getPrefixKey from "./getPrefixKey";

const isVisibleLanguageSwitch = () => {
  const isVisible = isLifeScience()
                 || getPrefixKey() === "en"
                 || getPrefixKey() === "neu";
  return isVisible;
};

export default isVisibleLanguageSwitch;
