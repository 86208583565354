import { prefixKeys } from "./getPrefixKey";

const getDepartmentKey = () => {
  const pathName = window.location.pathname
    .split("/")
    .filter((item) => item); // removes empty strings from resulting Array

  if (prefixKeys.includes(pathName[0])) pathName.shift();
  const departmentKey = pathName.shift();

  return departmentKey;
};

export default getDepartmentKey;
