import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { scrollToHash } from '../../global-effects';

// Components
import CookieConsentBox from '../CookieConsentBox';
import PageViewTracker from '../PageViewTracker';
import HeaderHelmet from '../HeaderHelmet';
import Loader from '../Loader';
import PageHeader from '../PageHeader';
import PageContent from '../PageContent';
import PageFooter from '../PageFooter';
import {
  getSubDomainKey,
  isMainWebsite,
  isPrerender,
  person2Team,
} from '../../utils';

// Services
import ApiService from '../../services/ApiService';

// Store
import { fetchMainMenu, selectMainMenu } from '../../state/store/slices/mainMenuSlice';
import { fetchSecondaryMenu, selectSecondaryMenu } from '../../state/store/slices/secondaryMenuSlice';
import { useAppSelector } from '../../state/hooks';

import './Page.scss';
import store from '../../state/store';
import getDepartmentKey from '../../utils/getDepartmentKey';
import getPrefixKey, { prefixKeys } from '../../utils/getPrefixKey';

interface IPageData {
  meta: any,
  header: {
    title: string;
    subTitle: string;
    image: any
  };
  contactPerson: any,
  team: any;
}

const Page = () => {
  // switch to different navigation when on topicArea
  const subDomainKey = getSubDomainKey(); // subDomainKey.syncwork.de <- determines mainNavigation
  const departmentKey = getDepartmentKey(); // syncwork.de/departmentKey <- determines secondaryNavigation
  const prefixKey = getPrefixKey();

  useEffect(() => {
    store.dispatch(fetchMainMenu(subDomainKey));
  }, [subDomainKey]);

  useEffect(() => {
    store.dispatch(fetchMainMenu(subDomainKey));
    store.dispatch(fetchSecondaryMenu(departmentKey));
  }, [departmentKey, prefixKey]);

  let { slug }: any = useParams();
  if (prefixKeys.includes(slug)) slug = ""; // just for the homepage, so we don't try to fetch the prefix as a slug

  const mainMenu = useAppSelector(selectMainMenu);
  const secondaryMenu = useAppSelector(selectSecondaryMenu);

  const scrolledRef = useRef(false);
  const { hash } = useLocation();

  const [data, setData] = useState<IPageData>({
    meta: {},
    header: {
      title: '',
      subTitle: '',
      image: null,
    },
    contactPerson: null,
    team: null,
  });
  const setDataAndPossiblyScroll = (data2) => {
    setData(data2);
    scrollToHash(scrolledRef, hash);
  };

  const getRedirectUrl = (currentUrl, forceSubDomainKey) => {
    if (subDomainKey === forceSubDomainKey) {
      return currentUrl;
    }

    const colonAndDoubleSlash = '://';
    let redirectUrl = currentUrl.replace(`${colonAndDoubleSlash}${subDomainKey}.`, colonAndDoubleSlash);

    if (!isMainWebsite(forceSubDomainKey)) {
      redirectUrl = redirectUrl.replace(colonAndDoubleSlash, `${colonAndDoubleSlash}${forceSubDomainKey}.`);
    }

    return redirectUrl;
  };

  // when the url changes, we may need to get the new Navigation
  useEffect(() => {
    if (subDomainKey) {
      store.dispatch(fetchMainMenu(subDomainKey));
    }
    store.dispatch(fetchSecondaryMenu(departmentKey || ""));
  }, [slug]);

  const getPageData = (pageSlug, prefix = "") => {
    const languageRequest = ""; // languageKey !== "" ? `&locale=${languageKey}` : "";
    // eslint-disable-next-line consistent-return
    ApiService.get(`/pages?slug=${prefix}${pageSlug}${languageRequest}`)
      .then(response => {
        if (!response.data.length) {
          if (prefix !== "") {
            getPageData(pageSlug);
          } else {
            ApiService.get(`/pages?slug=${mainMenu.notFoundPage.slug}`)
              .then(notFoundPageResponse => {
                setDataAndPossiblyScroll(notFoundPageResponse.data[0]);
              });
          }
        } else {
          const responseData = response.data[0];

          if (responseData.forceMenu) {
            ApiService.get(`/menus?id=${responseData.forceMenu}`)
              .then(response2 => {
                const forceSubDomainKey = response2.data[0].subDomainKey;

                if (subDomainKey === forceSubDomainKey || isPrerender()) {
                  setDataAndPossiblyScroll(responseData);
                } else {
                  window.location.href = getRedirectUrl(window.location.href, forceSubDomainKey);
                }
              });
          } else {
            setDataAndPossiblyScroll(responseData);
          }
        }
      });
  };

  useEffect(() => {
    // wait for mainMenu request to complete
    if (!mainMenu) {
      return;
    }

    // "prefix" handles "neu"- as well as "en"-pages
    const prefix = getPrefixKey();
    if (prefix !== "") {
      getPageData(slug || mainMenu.homePage.slug, `${prefix}-`);
    } else {
      getPageData(slug || mainMenu.homePage.slug);
    }
  }, [mainMenu, window.location.pathname]);

  // show loader as long there is no site menu
  if (!mainMenu && !secondaryMenu) {
    return (
      <div className="sw-page">
        <Loader />
      </div>
    );
  }

  // page data exists
  if (data.contactPerson) {
    data.team = person2Team(data.contactPerson);
  }

  return (
    <>
      <CookieConsentBox googleTagManagerId={mainMenu?.googleTagManagerId} />
      <PageViewTracker />
      <HeaderHelmet header={data.header} mainMenu={mainMenu} meta={data.meta} />
      <div className="sw-page">
        <PageHeader data={data} />
        <PageContent data={data} />
        <PageFooter team={data.team} />
      </div>
    </>
  );
};

export default Page;
