import { useLocation } from "react-router-dom";

const isLifeScience = () => {
  const location = useLocation();
  const changeLanguageLink = location.pathname
    .split("/")
    .filter((item) => item);

  return changeLanguageLink.includes("life-science");
};

export default isLifeScience;
