import "./HamburgerMenu.scss";

/**
 * @return {html}
 */
const HamburgerMenu = ({ toggleMobileMenu, activeMobileMenu }: any) => (
  <button
    className={`hamburger hamburger--slider${activeMobileMenu ? " is-active" : ""}`}
    type="button"
    onClick={toggleMobileMenu}
  >
    <span className="hamburger-box">
      <span className="hamburger-inner" />
    </span>
  </button>
);

export default HamburgerMenu;
