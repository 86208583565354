export const prefixKeys = [
  'neu',
  'en',
  'de',
];

const getPrefixKey = () => {
  const firstUrlPathSegment = window.location.pathname
    .split("/")
    .filter((item) => item)[0]; // filter function removes empty strings from resulting Array

  if (prefixKeys.includes(firstUrlPathSegment)) {
    return firstUrlPathSegment;
  }

  return "";
};

export default getPrefixKey;
