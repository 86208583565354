import { configureStore } from '@reduxjs/toolkit';

import jobsReducer from './slices/jobsSlice';
import mainMenuReducer from './slices/mainMenuSlice';
import secondaryMenuReducer from './slices/secondaryMenuSlice';
import newsItemsReducer from './slices/newsItemsSlice';
import publicationsReducer from './slices/publicationsSlice';

const store = configureStore({
  reducer: {
    jobs: jobsReducer,
    navigation: mainMenuReducer,
    secondaryNavigation: secondaryMenuReducer,
    newsItems: newsItemsReducer,
    publications: publicationsReducer,
  },
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the index itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
