import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiService from '../../../services/ApiService';
import Statuses from '../../types/Statuses';
import getPrefixKey from '../../../utils/getPrefixKey';

interface ISecondaryMenuSlice {
  secondaryMenu: any;
  status: string;
  error: string;
}

const initialState: ISecondaryMenuSlice = {
  secondaryMenu: null,
  status: Statuses.idle,
  error: null,
};

// TopicArea
// for Homepage & Karriere
export const fetchSecondaryMenu = createAsyncThunk('secondaryNavigation/fetchSecondaryMenu', async (departmentKey: string) => {
  let prefixKey = getPrefixKey();

  if (departmentKey === "" || prefixKey === departmentKey) return false; // to reset/empty secondary Navigation
  if (prefixKey) { prefixKey += "-"; }

  const baseUrl = '/menus';
  let response = await ApiService.get(`${baseUrl}?subDomainKey=${prefixKey}${departmentKey}`);
  if (!response.data.length) {
    const url = `${baseUrl}?subDomainKey=${departmentKey}`;
    response = await ApiService.get(url);
  }
  const responseData = response.data[0];

  if (responseData.useMenuItemsFromAnotherMenu) {
    const secondUrl = `${baseUrl}?id=${responseData.useMenuItemsFromAnotherMenu}`;
    const secondResponse = await ApiService.get(secondUrl);
    const secondResponseData = secondResponse.data[0];

    responseData.menuItems = secondResponseData.menuItems;
    delete responseData.useMenuItemsFromAnotherMenu;
  }

  return responseData;
});

export const secondaryMenuSlice = createSlice({
  name: 'secondaryNavigation',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSecondaryMenu.pending, (state) => {
        state.status = Statuses.loading;
      })
      .addCase(fetchSecondaryMenu.fulfilled, (state, action) => {
        state.status = Statuses.succeeded;
        // Add fetched secondaryMenu to the store
        state.secondaryMenu = action.payload;
      })
      .addCase(fetchSecondaryMenu.rejected, (state, action) => {
        state.secondaryMenu = null;
        state.status = Statuses.failed;
        state.error = action.error.message;
      });
  },
});

export const selectSecondaryMenu = state => state.secondaryNavigation.secondaryMenu;
export default secondaryMenuSlice.reducer;
